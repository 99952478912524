<template>
  <div
      :class="type ? `d-inline-block ${type} h5 shadow-sm p-2 mb-3 font-weight-bolder rounded border`
       : 'd-inline-block text-dark h5  shadow-sm p-2 mb-3 font-weight-bolder rounded border'">
    {{ name }}
  </div>
</template>
<script>
export default {
  name: "SectionHeader",
  props: ["name", "type"]
};
</script>