<template>
  <b-row>
    <b-col>
      <slot></slot>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "RowCol"
};
</script>