<template>
  <div>
    <Navbar/>
    <b-container class="text-center">
      <RowCol>
        <SectionHeader name="Dubay Sifarişləri"/>
      </RowCol>
      <RowCol>
        <b-table class="shadow" bordered hover small :items="items" :fields="fields">
          <template #cell(pub_date)="data">
            {{ data.item.pub_date | format_date }}
          </template>
        </b-table>
      </RowCol>
    </b-container>

  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
import RowCol from "@/components/RowCol";
import SectionHeader from "@/components/SectionHeader";

export default {
  name: "DubaiOrders",
  components: {SectionHeader, RowCol, Navbar},
  data() {
    return {
      currentPage: 1,
      items: [],
      fields: [
        {key: "pub_date", label: "Tarix"},
        {key: "brand", label: "Brend"},
        {key: "kod", label: "Kod"},
        {key: "quantity", label: "Say"},
        {key: "price", label: "Qiymət (AZN) "},
        {key: "comment", label: "Qeyd"},
        {key: "status", label: "Status"},
      ],
      total: null,
      disabled: false,
    };
  },
  created() {
    this.fetchResults();
    this.$store.commit("SET_SECTION", "DUBAY SİFARİŞLƏRİ");
  },

  methods: {

    fetchResults() {
      this.disabled = true;
      const data = {page: this.currentPage};
      axios.post("/dubai_orders", data).then(data => {
        this.disabled = false;
        this.items = data.data.results;
      });
    },
  }
};
</script>